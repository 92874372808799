import { GraphQLClient } from 'graphql-request';
import { applicationProperties } from '@/constants/applicationProperties';
import { SiweJwt } from '@/entities/SiweJwt';

export const graphqlApiClient = () => {
  const atSiweJwt: string | null = localStorage.getItem('nftticket.siweJwt');
  const secretJwt = atSiweJwt ? new SiweJwt(JSON.parse(atSiweJwt)).accessToken : null;
  const headers = secretJwt
    ? {
        authorization: `Bearer ${secretJwt}`,
      }
    : undefined;

  return new GraphQLClient(applicationProperties.API_GQL_URL, {
    method: 'POST',
    headers,
  });
};
