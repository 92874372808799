import ERROR_MESSAGES from '@/constants/errorMessages';
import { FieldErrors } from 'react-hook-form';
import { toast } from 'react-toastify';

export const getParentItem = (value: string | number, list: any[]) => list.find((item) => item.value === value);

export const getFormErrMessage = (name: string, err: FieldErrors) => err[name]?.message ?? '';

export const onCopyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.info('クリップボードにコピーしました');
};

export const delay = (ms: number = 3000) => new Promise((resolve) => setTimeout(resolve, ms));

export const showError = (error: any) => {
  const errorCode = error.response.errors[0].extensions.code;
  toast.error(ERROR_MESSAGES[errorCode as keyof typeof ERROR_MESSAGES]);
};
