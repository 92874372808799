import DefaultLayout from '@/components/layout';
import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { Noto_Sans_JP, Outfit } from 'next/font/google';
import GoogleAnalyticsV4, { GoogleAnalyticsId } from './GoogleAnalyticsV4';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { Page } from '@/types/page';
import { applicationProperties } from '@/constants/applicationProperties';
import { RecoilRoot } from 'recoil';
import Middleware from '@/middleware/middleware';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import dynamic from 'next/dynamic';
const PwaDrawer = dynamic(() => import('@/components/PwaDrawer'), {
  ssr: false,
});

const noto = Noto_Sans_JP({ subsets: ['latin'], variable: '--font-noto' });
const outfit = Outfit({ subsets: ['latin'], variable: '--font-outfit' });

// rollbar setups
const rollbarConfig = {
  accessToken: `${applicationProperties.ROLLBAR_ACCESS_TOKEN}`,
  environment: `${applicationProperties.ROLLBAR_ENVIRONMENT}`,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
};

type AppPropsWithLayout = AppProps & {
  Component: Page;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  const Layout =
    (Component as Page).layout ||
    (({ children }: { children: ReactNode }) => <DefaultLayout>{children}</DefaultLayout>);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <GoogleAnalyticsV4 googleAnalyticsId={applicationProperties.GOOGLE_ANALYTICS_ID as GoogleAnalyticsId} />
        <RecoilRoot>
          <Middleware>
            <main className={`font-noto ${noto.variable} ${outfit.variable}`}>
              <Layout>
                <Component {...pageProps} key={router.asPath} />
                <PwaDrawer />
              </Layout>
              <ToastContainer
                position="bottom-center"
                className="!px-4 !pb-4"
                toastClassName="!px-3 !py-2.5 !shadow-toast !rounded-[15px] font-bold !font-noto !text-black"
                closeButton={false}
                // hideProgressBar
                // icon={false}
                autoClose={3000}
              />
            </main>
          </Middleware>
        </RecoilRoot>
      </ErrorBoundary>
    </Provider>
  );
}
