import { graphqlApiClient } from '@/apis/GraphqlApiClient';
import {
  CreateUserDocument,
  CreateUserMutation,
  CreateUserMutationVariables,
  UserDocument,
  UserEntity,
  UserQuery,
} from '@/generated/graphql';

const findOneByJwt = async (): Promise<UserEntity | null> => {
  const userQuery = await graphqlApiClient().request<UserQuery>(UserDocument);

  return userQuery.user || null;
};

const create = async (variables?: CreateUserMutationVariables): Promise<UserEntity> => {
  const createUserMutation = await graphqlApiClient().request<CreateUserMutation>(CreateUserDocument, variables);

  return createUserMutation.createUser;
};

export const UserRepository = { findOneByJwt, create };
