import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import useSWR from 'swr';
import { SiweJwt } from '@/entities/SiweJwt';
import { SiweJwtRepository } from '@/repositories/SiweJwtRepository';
import { authenticatedStore } from './authenticatedStore';

const isAvailableJwt = (jwt: SiweJwt) => {
  return jwt.expiresAt > Date.now() && (jwt.accessToken ? true : false);
};

export const useAuthenticatedStore = () => {
  const [authenticated, setAuthenticated] = useRecoilState(authenticatedStore);

  const { data } = useSWR(
    'authKey',
    async () => {
      return await SiweJwtRepository.getSiweJwtFromBrowser();
    },
    {
      refreshInterval: 5000,
    }
  );

  useEffect(() => {
    setAuthenticated(data);
  }, [data]);

  const isAuthenticated = () => {
    const jwtAvailable = authenticated ? isAvailableJwt(authenticated) : false;

    return jwtAvailable;
  };

  return { authenticated, isAuthenticated };
};
