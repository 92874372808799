import { SiweJwt } from '@/entities/SiweJwt';
import { UserEntity } from '@/generated/graphql';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export type AuthenticationStatus = 'loading' | 'unauthenticated' | 'authenticated';

export const authenticatedStore = atom<SiweJwt | null | undefined>({
  key: 'authenticatedStore',
  default: undefined,
});

export const userStore = atom<UserEntity>({
  key: 'userStore',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
