import { useEffect, useState } from 'react';
import Link from 'next/link';
import { onCopyToClipboard } from '@/utils/common';
import { inquiryUrl, signInUrl, userTicketsUrl } from '@/helpers/url.helper';

import HamburgerIcon from 'assets/icons/hamburger-icon.svg';
import CopyIcon from 'assets/icons/copy-icon.svg';
import Overlay from './Overlay';
import { useWeb3Auth } from '@/hooks/useWeb3Auth';
import { SiweEoaAddressRepository } from '@/repositories/SiweEoaAddressRepository';
import useGAEvent from '@/hooks/useGAEvent';
import { GA4_SCREEN_NAME } from '@/constants/ga4CustomEvent';
import { OUTER_URLS } from '@/constants/common';

const menuList = [
  {
    name: 'チケット一覧',
    link: userTicketsUrl(),
  },
  {
    name: 'ご利用方法',
    link: OUTER_URLS.HOW_TO_USE,
    isOuter: true,
  },
  {
    name: 'よくある質問',
    link: OUTER_URLS.FAQ,
    isOuter: true,
  },
  {
    name: '利用規約',
    link: OUTER_URLS.TERM,
    isOuter: true,
  },
  {
    name: 'プライバシーポリシー',
    link: OUTER_URLS.PRIVACY,
    isOuter: true,
  },
  {
    name: 'お問い合わせ',
    link: inquiryUrl(),
    isOuter: true,
  },
];

export default function Menu() {
  const { pushShowScreenGAEvent } = useGAEvent();
  const [isOpen, setIsOpen] = useState(false);
  const { web3AuthLogout } = useWeb3Auth();
  const [eoaAddress, setEoaAddress] = useState<string>('');

  useEffect(() => {
    isOpen && pushShowScreenGAEvent(GA4_SCREEN_NAME.POPUP_MENU_SCREEN);
  }, [isOpen, pushShowScreenGAEvent]);

  useEffect(() => {
    SiweEoaAddressRepository.get().then((address) => {
      address && setEoaAddress(address);
    });
  }, []);

  return (
    <>
      <div
        className="flex items-center justify-center h-[44px] w-[44px] cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <HamburgerIcon />
      </div>
      {isOpen && (
        <Overlay onClose={() => setIsOpen(false)}>
          <div className="flex flex-col w-full flex-grow justify-center items-center gap-8">
            {menuList.map((menu, index) =>
              menu?.isOuter ? (
                <a href={menu.link} key={index} target="_blank" rel="noreferrer">
                  <span className="font-outfit text-white text-xl font-bold">{menu.name}</span>
                </a>
              ) : (
                <Link href={menu.link} key={index}>
                  <span className="font-outfit text-white text-xl font-bold">{menu.name}</span>
                </Link>
              )
            )}
            <span className="text-white text-xl font-bold" onClick={() => web3AuthLogout(signInUrl())}>
              ログアウト
            </span>
          </div>
          {!!eoaAddress && (
            <div className="flex justify-center items-center gap-2 text-gray2 font-light text-xs">
              <span className="font-outfit max-w-[calc(100%-36px)] overflow-hidden text-ellipsis">{eoaAddress}</span>
              <CopyIcon onClick={() => onCopyToClipboard(eoaAddress)} />
            </div>
          )}
        </Overlay>
      )}
    </>
  );
}
