import { signInUrl, ticketEntranceUrl, web3AuthCallbackUrl } from '@/helpers/url.helper';
import { useAuthenticatedStore } from '@/recoil/authenticatedStore/useAuthenticatedStore';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { Fragment, ReactNode, useEffect, useState } from 'react';

const Middleware = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { uniqueKey } = router.query;
  const pathname = usePathname();
  const [loaded, setLoaded] = useState(false);
  const { isAuthenticated } = useAuthenticatedStore();

  useEffect(() => {
    const handleRedirectRouter = async () => {
      if (!pathname || !loaded) {
        return;
      }

      const publicUrls = [signInUrl(), web3AuthCallbackUrl(), ticketEntranceUrl(uniqueKey as string)];
      if (!isAuthenticated() && publicUrls.includes(pathname)) {
        return;
      }

      if (!isAuthenticated() && !publicUrls.includes(pathname)) {
        router.push(signInUrl());
        return;
      }

      return;
    };

    handleRedirectRouter();
  }, [pathname, isAuthenticated, router, uniqueKey]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default Middleware;
