import { IProvider, WALLET_ADAPTERS } from '@web3auth/base';
import { Web3Auth, Web3AuthOptions } from '@web3auth/modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { ethers } from 'ethers';
import { useEffect, useRef, useState } from 'react';
import { SiweMessage } from 'siwe';
import { applicationProperties } from '@/constants/applicationProperties';
import { Web3AuthAlreadyConnectedError, Web3AuthNotConnectedError } from '@/exceptions/exceptions';
import { userNewUrl, userProfileNewUrl, userTicketsUrl, web3AuthCallbackUrl } from '@/helpers/url.helper';
import { SiweEoaAddressRepository } from '@/repositories/SiweEoaAddressRepository';
import { SiweJwtRepository } from '@/repositories/SiweJwtRepository';
import { UserRepository } from '@/repositories/UserRepository';
import { Web3AuthConnectedRepository } from '@/repositories/Web3AuthConnectedRepository';
import { SiweNonceRepository } from '@/repositories/SiweNonceRepository';
import { TicketRepository } from '@/repositories/TicketRepository';
import { useRouter } from 'next/router';
import { UserEntity } from '@/generated/graphql';
import { UserTicketRepository } from '@/repositories/UserTicketRepository';
import { delay } from '@/utils/common';
import { useSetRecoilState } from 'recoil';
import { authenticatedStore, userStore } from '@/recoil/authenticatedStore/authenticatedStore';
import { toast } from 'react-toastify';

export const useWeb3Auth = () => {
  const router = useRouter();
  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>();
  const [eoaAddress, setEoaAddress] = useState<string | null>(null);
  // TODO persistしたisCheckConnectedAyncに置き換える
  const [isWeb3AuthConnected, setIsWeb3AuthConnected] = useState<boolean>(false);

  const initializing = useRef(false); // useRefを使って初期化の状態を追跡

  const setSecretJwt = useSetRecoilState(authenticatedStore);
  const setUser = useSetRecoilState(userStore);
  // 初期化処理
  useEffect(() => {
    (async () => {
      if (initializing.current) return;
      if (web3Auth) return;

      initializing.current = true;

      await initializeWeb3Auth();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ウォレットアドレス取得およびキャッシュ処理
  useEffect(() => {
    (async () => {
      if (web3Auth?.status === 'connected') {
        await Web3AuthConnectedRepository.setWeb3AuthConnected();
        setIsWeb3AuthConnected(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Auth]);

  const openloginAdapter = new OpenloginAdapter({
    loginSettings: {
      mfaLevel: 'none', // default, optional, mandatory, none
    },
    adapterSettings: {
      uxMode: 'redirect', // safariは　popup が使えないため明示的に redirect を指定する
      redirectUrl: `${applicationProperties.HOSTING_URL}${web3AuthCallbackUrl()}`,
    },
  });

  // web3Authオブジェクトを初期化し、Stateに設定する
  const initializeWeb3Auth = async (): Promise<Web3Auth> => {
    // 1.接続チェーン等の設定
    const web3AuthObject = new Web3Auth(web3AuthOptions);
    // 2.ログインモーダルとMFAの設定 3rdPartyCookie対策はここで行う
    web3AuthObject.configureAdapter(openloginAdapter);
    // 3.ログインモーダルに表示するログイン手法の選定をし、モーダルを初期化する
    await web3AuthObject.initModal(modalConfig);
    // 4.初期化したオブジェクトをStateに設定し、外部から参照できるようにする
    setWeb3Auth(web3AuthObject);

    return web3AuthObject;
  };

  // web3Authのログアウト処理
  const web3AuthLogout = async (redirectUrl: string) => {
    try {
      // siweの削除
      await SiweJwtRepository.removeSiweJwtFromBrowser();

      // web3Authのログアウト処理
      if (!isWeb3AuthConnected) throw new Web3AuthNotConnectedError();
      await SiweEoaAddressRepository.remove();
      await SiweJwtRepository.removeSiweJwtFromBrowser();
      await TicketRepository.removeFromBrowser();
      await web3Auth?.logout();
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      // ログアウト後の処理
      setWeb3Auth(null);
      router.push(redirectUrl);
    }
  };

  // web3Authのユーザー情報取得処理
  const getUserInfo = async () => {
    try {
      if (!isWeb3AuthConnected) throw new Web3AuthNotConnectedError();
      return web3Auth?.getUserInfo();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  // web3Authに接続しウォレットでログインする処理
  const connectWeb3Auth = async () => {
    try {
      if (isWeb3AuthConnected) throw new Web3AuthAlreadyConnectedError();
      await web3Auth?.connect();
    } catch (e) {
      console.error(e);
      if (!`${e}`.includes('User closed the modal')) {
        throw e;
      }
    }
  };

  // web3authにログインしているかどうかを判定する
  const isCheckConnectedAync = async () => {
    const { connected } = await Web3AuthConnectedRepository.getWeb3AuthConnected();

    return !!connected;
  };

  // web3Authでログインを行った上でSIWEする
  const connectWeb3AuthAndSignInWithEthereum = async () => {
    if (!web3Auth) return;
    try {
      const web3AuthProvider: IProvider | null = await web3Auth?.connect();
      console.log(web3AuthProvider);
      if (!web3AuthProvider) return;

      await _signInWithEthereum(web3AuthProvider);
    } catch (e) {
      console.error(e);
      if (!`${e}`.includes('User closed the modal')) {
        throw e;
      }
    }
  };

  // SNSログイン後にcallbackした時にSIWEする
  const callbackedSignInWithEthereum = async () => {
    if (!web3Auth) return;
    try {
      const web3AuthProvider: IProvider | null = await web3Auth?.connect();
      if (!web3AuthProvider) return;

      await _signInWithEthereum(web3AuthProvider);
    } catch (e) {
      console.error(e);
      if (!`${e}`.includes('User closed the modal')) {
        throw e;
      }
    }
  };

  const _signInWithEthereum = async (web3AuthProvider: IProvider) => {
    try {
      // 1.web3AuthProviderをethers.jsのProviderに変換する
      const provider = new ethers.providers.Web3Provider(web3AuthProvider);
      // 2.変換したProviderからSignerを取得する
      const signer = await provider.getSigner();
      // 3.SignerからEOAアドレスを取得する
      const signinedEoaAddress = await signer.getAddress();
      // 4.接続先サーバーよりnonceを取得する
      const nonce = await SiweNonceRepository.challenge();
      // 5.SIWEメッセージを生成する
      const siweMessage = new SiweMessage({
        domain: applicationProperties.HOSTING_DOMAIN,
        address: signinedEoaAddress,
        statement: 'Sign in with Ethereum to the app.',
        uri: applicationProperties.HOSTING_URL,
        version: '1',
        chainId: 1,
        nonce,
      });
      // 6.メッセージをstring化し、署名を行う
      const message = siweMessage.prepareMessage();
      const signature = await signer.signMessage(message);

      // 7.署名したメッセージをサーバーに送信し、検証を行ったのち、JWTをキャッシュする
      const siweJwt = await SiweJwtRepository.login(siweMessage, signature);
      await SiweJwtRepository.saveSiweJwtToBrowser(siweJwt);
      setEoaAddress(signinedEoaAddress);

      // 8.ログイン後のユーザーアップデート処理
      const jwt = await SiweJwtRepository.getSiweJwtFromBrowser();
      await SiweEoaAddressRepository.save(signinedEoaAddress);
      if (!jwt) throw new Error('jwt is not found');
      setSecretJwt(jwt);

      const user = await UserRepository.findOneByJwt();
      // const user = await UserRepository.findOneByJwt(jwt.accessToken);

      await handleNavigateUrl(user);
    } catch (e: any) {
      console.error(e);
      if (e.response?.errors && e.response?.errors[0]?.extensions?.code === 'NO_USER_FOUND') {
        handleNavigateUrl(null);
      }
    }
  };

  const handleNavigateUrl = async (user: UserEntity | null) => {
    const ticketUniqueKeyEntrance = await TicketRepository.getFromBrowser();

    await delay();
    if (!user) {
      router.push(userNewUrl(ticketUniqueKeyEntrance as string));
      return;
    }

    setUser(user);

    if (!ticketUniqueKeyEntrance) {
      router.push(userTicketsUrl());
      return;
    }

    const droppable = await UserTicketRepository.droppable({ ticketUniqueKey: ticketUniqueKeyEntrance });
    if (droppable) {
      router.push(userTicketsUrl());
      toast.info('すでにこのチケットを取得しています');
      return;
    }

    router.push(userProfileNewUrl(ticketUniqueKeyEntrance));
  };

  return {
    connectWeb3Auth,
    connectWeb3AuthAndSignInWithEthereum,
    callbackedSignInWithEthereum,
    web3Auth,
    initializeWeb3Auth,
    getUserInfo,
    web3AuthLogout,
    isWeb3AuthConnected,
    isCheckConnectedAync,
    eoaAddress,
    handleNavigateUrl,
  };
};

// private ---

const web3AuthOptions: Web3AuthOptions = {
  clientId: applicationProperties.WEB3AUTH_CLIENT_ID,

  web3AuthNetwork: applicationProperties.WEB3AUTH_AUTH_NETWORK as Web3AuthOptions['web3AuthNetwork'],
  chainConfig: {
    chainNamespace: 'eip155',
    chainId: '0x1',
    rpcTarget: 'https://rpc.ankr.com/eth',
    displayName: 'Ethereum Mainnet',
    blockExplorer: 'https://etherscan.io',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  },

  uiConfig: {
    defaultLanguage: 'ja',
    tncLink: { ja: 'https://drive.google.com/file/d/19R26joGpQMfNBAkT4fDwb2EFy0FW0b0_/view' },
    privacyPolicy: { ja: 'https://www.scsk.jp/privacy.html' },
  },
};

const modalConfig = {
  modalConfig: {
    [WALLET_ADAPTERS.OPENLOGIN]: {
      label: 'openlogin',
      loginMethods: {
        apple: {
          name: 'apple',
          showOnModal: true,
        },
        email_passwordless: {
          name: 'email_passwordless',
          showOnModal: true,
        },
        facebook: {
          name: 'facebook',
          showOnModal: false,
        },
        reddit: {
          name: 'reddit',
          showOnModal: false,
        },
        discord: {
          name: 'discord',
          showOnModal: false,
        },
        twitch: {
          name: 'twitch',
          showOnModal: false,
        },
        line: {
          name: 'line',
          showOnModal: false,
        },
        github: {
          name: 'github',
          showOnModal: false,
        },
        kakao: {
          name: 'kakao',
          showOnModal: false,
        },
        linkedin: {
          name: 'linkedin',
          showOnModal: false,
        },
        twitter: {
          name: 'twitter',
          showOnModal: false,
        },
        weibo: {
          name: 'weibo',
          showOnModal: false,
        },
        wechat: {
          name: 'wechat',
          showOnModal: false,
        },
        sms_passwordless: {
          name: 'phone',
          showOnModal: false,
        },
      },
      showOnModal: true,
    },
  },
};
