import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: any; output: any; }
};

export type AdminCreateTicketInput = {
  beforeDropDescription: Scalars['String']['input'];
  consumeableEndAt: Scalars['ISO8601DateTime']['input'];
  consumeableStartAt: Scalars['ISO8601DateTime']['input'];
  description: Scalars['String']['input'];
  dropEndAt: Scalars['ISO8601DateTime']['input'];
  dropStartAt: Scalars['ISO8601DateTime']['input'];
  enable: Scalars['Boolean']['input'];
  eventEndAt: Scalars['ISO8601DateTime']['input'];
  eventPlaceName: Scalars['String']['input'];
  eventStartAt: Scalars['ISO8601DateTime']['input'];
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  stockedCount: Scalars['Float']['input'];
};

export type AdminTicketEntity = {
  __typename?: 'AdminTicketEntity';
  beforeDropDescription: Scalars['String']['output'];
  consumeableEndAt: Scalars['ISO8601DateTime']['output'];
  consumeableStartAt: Scalars['ISO8601DateTime']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  dropEndAt: Scalars['ISO8601DateTime']['output'];
  dropStartAt: Scalars['ISO8601DateTime']['output'];
  droppableCount: Scalars['Float']['output'];
  droppedCount: Scalars['Float']['output'];
  enable: Scalars['Boolean']['output'];
  eventEndAt: Scalars['ISO8601DateTime']['output'];
  eventPlaceName: Scalars['String']['output'];
  eventStartAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Float']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stockedCount: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AdminUpdateTicketInput = {
  beforeDropDescription?: InputMaybe<Scalars['String']['input']>;
  consumeableEndAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  consumeableStartAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dropEndAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dropStartAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  eventEndAt: Scalars['ISO8601DateTime']['input'];
  eventPlaceName?: InputMaybe<Scalars['String']['input']>;
  eventStartAt: Scalars['ISO8601DateTime']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stockedCount?: InputMaybe<Scalars['Float']['input']>;
  uniqueKey: Scalars['String']['input'];
};

export type AdminUserTicketEntity = {
  __typename?: 'AdminUserTicketEntity';
  awareness?: Maybe<Scalars['String']['output']>;
  childrenAges?: Maybe<Scalars['String']['output']>;
  childrenCount?: Maybe<Scalars['Float']['output']>;
  consumedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Float']['output'];
  maritalStatus?: Maybe<Scalars['String']['output']>;
  preferredTicketType?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  ticket: TicketEntity;
  uniqueKey: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: UserEntity;
  visitCount?: Maybe<Scalars['Float']['output']>;
};

export type CreateUserInput = {
  dateOfBirthOn?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserTicketInput = {
  awareness?: InputMaybe<Scalars['String']['input']>;
  childrenAges?: InputMaybe<Scalars['String']['input']>;
  childrenCount?: InputMaybe<Scalars['Float']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  preferredTicketType?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  ticketUniqueKey: Scalars['String']['input'];
  visitCount?: InputMaybe<Scalars['Float']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminCreateTicket: AdminTicketEntity;
  adminUpdateTicket: AdminTicketEntity;
  consumeUserTicket: UserTicketEntity;
  createUser: UserEntity;
  createUserTicket: UserTicketEntity;
};


export type MutationAdminCreateTicketArgs = {
  adminCreateTicketInput: AdminCreateTicketInput;
};


export type MutationAdminUpdateTicketArgs = {
  adminUpdateTicketInput: AdminUpdateTicketInput;
};


export type MutationConsumeUserTicketArgs = {
  userTicketUniqueKey: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserTicketArgs = {
  input: CreateUserTicketInput;
};

export type Paging = {
  __typename?: 'Paging';
  currentPage: Scalars['Float']['output'];
  limit: Scalars['Float']['output'];
  pages: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PagingTicket = {
  __typename?: 'PagingTicket';
  data: Array<AdminTicketEntity>;
  meta: Paging;
};

export type PagingUserTicket = {
  __typename?: 'PagingUserTicket';
  data: Array<AdminUserTicketEntity>;
  meta: Paging;
};

export type Query = {
  __typename?: 'Query';
  adminLogin: SignInResponse;
  adminLogout: Scalars['String']['output'];
  adminTicket: AdminTicketEntity;
  adminTickets: PagingTicket;
  adminUserTickets: PagingUserTicket;
  droppableUserTicket: Scalars['Boolean']['output'];
  ticket: TicketEntity;
  user: UserEntity;
  userTicket: UserTicketEntity;
  userTickets: Array<UserTicketEntity>;
};


export type QueryAdminLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type QueryAdminTicketArgs = {
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryAdminTicketsArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
};


export type QueryAdminUserTicketsArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryDroppableUserTicketArgs = {
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryTicketArgs = {
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryUserTicketArgs = {
  userTicketUniqueKey: Scalars['String']['input'];
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  accessToken: Scalars['String']['output'];
  expiresAt: Scalars['Float']['output'];
  expiresIn: Scalars['Float']['output'];
  tokenType: Scalars['String']['output'];
};

export type TicketEntity = {
  __typename?: 'TicketEntity';
  beforeDropDescription: Scalars['String']['output'];
  consumeableEndAt: Scalars['ISO8601DateTime']['output'];
  consumeableStartAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  dropEndAt: Scalars['ISO8601DateTime']['output'];
  dropStartAt: Scalars['ISO8601DateTime']['output'];
  droppableCount: Scalars['Float']['output'];
  droppedCount: Scalars['Float']['output'];
  enable: Scalars['Boolean']['output'];
  eventEndAt: Scalars['ISO8601DateTime']['output'];
  eventPlaceName: Scalars['String']['output'];
  eventStartAt: Scalars['ISO8601DateTime']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stockedCount: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
};

export type UserEntity = {
  __typename?: 'UserEntity';
  dateOfBirthOn?: Maybe<Scalars['ISO8601DateTime']['output']>;
  eoaAddress: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  uniqueKey: Scalars['String']['output'];
};

export type UserTicketEntity = {
  __typename?: 'UserTicketEntity';
  awareness?: Maybe<Scalars['String']['output']>;
  childrenAges?: Maybe<Scalars['String']['output']>;
  childrenCount?: Maybe<Scalars['Float']['output']>;
  consumedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  preferredTicketType?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  ticket: TicketEntity;
  uniqueKey: Scalars['String']['output'];
  user: UserEntity;
  visitCount?: Maybe<Scalars['Float']['output']>;
};

export type TicketFieldFragment = { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, enable: boolean, dropStartAt: any, dropEndAt: any, consumeableStartAt: any, consumeableEndAt: any, stockedCount: number, droppedCount: number, droppableCount: number };

export type UserTicketFieldFragment = { __typename?: 'UserTicketEntity', uniqueKey: string, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean } };

export type UserFieldFragment = { __typename?: 'UserEntity', uniqueKey: string, eoaAddress: string };

export type ConsumeUserTicketMutationVariables = Exact<{
  userTicketUniqueKey: Scalars['String']['input'];
}>;


export type ConsumeUserTicketMutation = { __typename?: 'Mutation', consumeUserTicket: { __typename?: 'UserTicketEntity', uniqueKey: string, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean } } };

export type CreateUserTicketMutationVariables = Exact<{
  ticketUniqueKey: Scalars['String']['input'];
  visitCount: Scalars['Float']['input'];
  awareness: Scalars['String']['input'];
  relationship: Scalars['String']['input'];
  maritalStatus: Scalars['String']['input'];
  childrenCount: Scalars['Float']['input'];
  childrenAges: Scalars['String']['input'];
  preferredTicketType: Scalars['String']['input'];
}>;


export type CreateUserTicketMutation = { __typename?: 'Mutation', createUserTicket: { __typename?: 'UserTicketEntity', uniqueKey: string, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean } } };

export type CreateUserMutationVariables = Exact<{
  nickname: Scalars['String']['input'];
  dateOfBirthOn: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  gender: Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserEntity', uniqueKey: string, eoaAddress: string } };

export type DroppableUserTicketQueryVariables = Exact<{
  ticketUniqueKey: Scalars['String']['input'];
}>;


export type DroppableUserTicketQuery = { __typename?: 'Query', droppableUserTicket: boolean };

export type TicketQueryVariables = Exact<{
  ticketUniqueKey: Scalars['String']['input'];
}>;


export type TicketQuery = { __typename?: 'Query', ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, enable: boolean, dropStartAt: any, dropEndAt: any, consumeableStartAt: any, consumeableEndAt: any, stockedCount: number, droppedCount: number, droppableCount: number } };

export type UserTicketQueryVariables = Exact<{
  userTicketUniqueKey: Scalars['String']['input'];
}>;


export type UserTicketQuery = { __typename?: 'Query', userTicket: { __typename?: 'UserTicketEntity', uniqueKey: string, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean } } };

export type UserTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTicketsQuery = { __typename?: 'Query', userTickets: Array<{ __typename?: 'UserTicketEntity', uniqueKey: string, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean } }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'UserEntity', uniqueKey: string, eoaAddress: string } };

export const TicketFieldFragmentDoc = gql`
    fragment TicketField on TicketEntity {
  uniqueKey
  name
  description
  beforeDropDescription
  imageUrl
  eventPlaceName
  eventStartAt
  eventEndAt
  enable
  dropStartAt
  dropEndAt
  consumeableStartAt
  consumeableEndAt
  stockedCount
  droppedCount
  droppableCount
}
    `;
export const UserTicketFieldFragmentDoc = gql`
    fragment UserTicketField on UserTicketEntity {
  uniqueKey
  ticket {
    uniqueKey
    name
    beforeDropDescription
    imageUrl
    eventPlaceName
    eventStartAt
    eventEndAt
    consumeableStartAt
    consumeableEndAt
    enable
  }
  consumedAt
}
    `;
export const UserFieldFragmentDoc = gql`
    fragment UserField on UserEntity {
  uniqueKey
  eoaAddress
}
    `;
export const ConsumeUserTicketDocument = gql`
    mutation consumeUserTicket($userTicketUniqueKey: String!) {
  consumeUserTicket(userTicketUniqueKey: $userTicketUniqueKey) {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const CreateUserTicketDocument = gql`
    mutation createUserTicket($ticketUniqueKey: String!, $visitCount: Float!, $awareness: String!, $relationship: String!, $maritalStatus: String!, $childrenCount: Float!, $childrenAges: String!, $preferredTicketType: String!) {
  createUserTicket(
    input: {ticketUniqueKey: $ticketUniqueKey, visitCount: $visitCount, awareness: $awareness, relationship: $relationship, maritalStatus: $maritalStatus, childrenCount: $childrenCount, childrenAges: $childrenAges, preferredTicketType: $preferredTicketType}
  ) {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const CreateUserDocument = gql`
    mutation createUser($nickname: String!, $dateOfBirthOn: String!, $postalCode: String!, $gender: String!) {
  createUser(
    input: {nickname: $nickname, dateOfBirthOn: $dateOfBirthOn, postalCode: $postalCode, gender: $gender}
  ) {
    ...UserField
  }
}
    ${UserFieldFragmentDoc}`;
export const DroppableUserTicketDocument = gql`
    query droppableUserTicket($ticketUniqueKey: String!) {
  droppableUserTicket(ticketUniqueKey: $ticketUniqueKey)
}
    `;
export const TicketDocument = gql`
    query ticket($ticketUniqueKey: String!) {
  ticket(ticketUniqueKey: $ticketUniqueKey) {
    ...TicketField
  }
}
    ${TicketFieldFragmentDoc}`;
export const UserTicketDocument = gql`
    query userTicket($userTicketUniqueKey: String!) {
  userTicket(userTicketUniqueKey: $userTicketUniqueKey) {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const UserTicketsDocument = gql`
    query userTickets {
  userTickets {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const UserDocument = gql`
    query user {
  user {
    ...UserField
  }
}
    ${UserFieldFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    consumeUserTicket(variables: ConsumeUserTicketMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ConsumeUserTicketMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ConsumeUserTicketMutation>(ConsumeUserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'consumeUserTicket', 'mutation');
    },
    createUserTicket(variables: CreateUserTicketMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserTicketMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserTicketMutation>(CreateUserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUserTicket', 'mutation');
    },
    createUser(variables: CreateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUser', 'mutation');
    },
    droppableUserTicket(variables: DroppableUserTicketQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DroppableUserTicketQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DroppableUserTicketQuery>(DroppableUserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'droppableUserTicket', 'query');
    },
    ticket(variables: TicketQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TicketQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TicketQuery>(TicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ticket', 'query');
    },
    userTicket(variables: UserTicketQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserTicketQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserTicketQuery>(UserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userTicket', 'query');
    },
    userTickets(variables?: UserTicketsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserTicketsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserTicketsQuery>(UserTicketsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userTickets', 'query');
    },
    user(variables?: UserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserQuery>(UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'user', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;